import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../types/user'

export type InitialStateType = {
  isFetching: boolean
  items: User[]
  error: string | null
}

export type FetchUserSelectorPayload = {
  search: string
}

export type FetchUserSelectorSuccessPayload = User[]
export type FetchUserSelectorFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  error: null,
}

export const userSelectorSlice = createSlice({
  name: 'userSelector',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchUserSelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchUserSelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchUserSelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    destroy(): InitialStateType {
      return initialState
    },
  },
})

export const actions = userSelectorSlice.actions

export default userSelectorSlice.reducer
