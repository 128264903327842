import { snakeCase } from 'change-case'

type ErrorType = {
  response?: {
    apiError?: {
      errors: string
      message: string
    }
    data?: {
      errors: string
      message: string
    }
  }
}

export const deepClone = (object1: any): any =>
  JSON.parse(JSON.stringify(object1))

export const isClient = (): boolean => typeof window !== 'undefined'

export const formatErrorMessage = (error: ErrorType): string => {
  return (
    (error?.response?.apiError?.errors &&
      Object.values(error?.response?.apiError?.errors).join(', ')) ||
    error?.response?.data?.message ||
    (error?.response?.data?.errors &&
      Object.values(error?.response?.data?.errors).join(', ')) ||
    error?.response?.apiError?.message ||
    'Что-то пошло не так при отправке данных'
  )
}

export type ErrorFieldsType = { name: string; errors: string[] }

export type ErrorFieldsPayload = {
  message: string
  fields: { [key: string]: string[] }
}

export type ErrorFormType = {
  message: string
  fields: ErrorFieldsType[]
}

export const buildFieldErrors = (errors: ErrorFieldsPayload): ErrorFormType => {
  const result: ErrorFieldsType[] = []

  for (const item in errors.fields) {
    result.push({
      name: snakeCase(item),
      errors: errors.fields[item],
    })
  }

  return {
    message: errors.message,
    fields: result,
  }
}

type JSONObject = { [key: string]: JSON }
type JSONArray = JSON[]
export type JSONType = null | string | number | boolean | JSONArray | JSONObject

export const safeJSONParse = (text: string): JSONType => {
  try {
    return JSON.parse(text)
  } catch {
    return null
  }
}

export const findNestedObj = <T, R>(
  entireObj: T,
  keyToFind: string,
  valToFind: string,
): R | undefined => {
  let foundObj
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue
    }
    return nestedValue
  })
  return foundObj
}

export const analyticsMainNames = {
  app_report_view: 'Просмотры',
  app_report_favorites: 'Добавлено в закладки',
  app_report_share: 'Поделились',
  api_fixed_company_users: 'Количество прикрепленных клиентов',
  api_company_sent_push_notifications: 'Отправлено уведомлений клиентам',
  api_favorite_companies: 'Компания сейчас в избранном',
}

export const analyticsSecondaryNames = {
  app_report_communicate: 'Связались',
  app_report_chat: 'Создали внутренний чат',
  app_report_site: 'Перешли на внешний сайт',
  app_report_click_action: 'Нажали кнопку действия',
  app_report_show_discount: 'Воспользовались скидкой при посещении',
}
