import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Area } from '../../types/area'
import { IMeta } from '../../types/mamado.types'

export type InitialStateType = {
  isFetching: boolean
  items: Area[]
  meta: IMeta | null
  error: string | null
}

export type FetchAreaTreeSelectorPayload = undefined

export type FetchAreaTreeSelectorSuccessPayload = Area[]

export type FetchAreaTreeSelectorFailurePayload = string | null

const initialState: InitialStateType = {
  isFetching: false,
  items: [],
  meta: null,
  error: null,
}

export const areaSelectorTreeSlice = createSlice({
  name: 'areaSelectorTree',
  initialState,
  reducers: {
    fetch(
      state: InitialStateType,
      _action: PayloadAction<FetchAreaTreeSelectorPayload>,
    ): void {
      state.isFetching = true
    },
    fetchSuccess(
      state: InitialStateType,
      action: PayloadAction<FetchAreaTreeSelectorSuccessPayload>,
    ): void {
      state.isFetching = false
      state.items = action.payload
    },
    fetchFailure(
      state: InitialStateType,
      action: PayloadAction<FetchAreaTreeSelectorFailurePayload>,
    ): void {
      state.isFetching = false
      state.error = action.payload
    },
    destroy(): InitialStateType {
      return initialState
    },
  },
})

export const actions = areaSelectorTreeSlice.actions

export default areaSelectorTreeSlice.reducer
